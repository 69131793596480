import * as serversTypes from "../Type/TypeServers";
import _ from "underscore";
import React from "react";
import "../Grid/Grid.css";
import { Icon } from "@abb/abb-common-ux-react";

const I = "I";
const ServerName = "ServerName";
const edgeName = "edgeName";
const edgeId = "id";
const status = "status";

interface IStatusColumnViewProps {
  type: "edge" | "server";
  statusArray: string[];
}
const StatusColumnView = (props: IStatusColumnViewProps) => {
  let statusView: JSX.Element[] = [];
  props.statusArray.map((item) => {
    if (item === "ready") {
      statusView.push(
        <div>
          <Icon name="abb/minus" color="#36f" sizeClass="large" />
        </div>
      );
    } else {
      statusView.push(
        <div className="disabled-status">
          <Icon name="abb/minus" color="grey" sizeClass="large" />
        </div>
      );
    }
  });
  if (props.type === "server") {
    statusView.push(
      <div
        className={
          props.statusArray[0] === "ready"
            ? "server-status-label"
            : "server-status-label disabled-status"
        }
      >
        {props.statusArray[0]}
      </div>
    );
  }
  return <div className="status-view-wrap">{statusView}</div>;
};

export const serversGridHeaders: serversTypes.ITableHeaderObj[] = [
  {
    id: "edges",
    displayName: "Edges",
    className: "users-list-header",
    isSortable: false,
  },
  // {
  //   id: "serverstatus",
  //   displayName: "SERVER STATUS",
  //   className: "users-list-header",
  //   isSortable: false,
  // },
];
export const formatServersData = (rawData: serversTypes.IRawServersObj[]) => {
  let formattedData: serversTypes.IServersGridRowObj[] = [];
  const keysToBeMapped = ["edges"];
  const rawDataCopy = [...rawData];
  const groupedData = _.groupBy(rawData, edgeId);
  console.log("check grouped Data", groupedData);
  console.log("check raw data", JSON.stringify(rawData));
  Object.keys(groupedData).map((key, keyIndex) => {
    const eachSetData: serversTypes.IServersGridRowObj[] = [];
    const eachEdgeRowObj: serversTypes.IServersGridRowObj = {
      id: `%${key}%`,
      displayName: `${groupedData[key][0][edgeName]}`,
      level: 1,
      isExpandable: false,
      isExpanded: false,
      isVisible: true,
      isClickable: false,
      rowType: "edge",
      isDeleteActionEnabled: false,
      isViewOptionsActionEnabled: true,
      data: [],
    };
    eachEdgeRowObj.data[0] = {
      id: "edges",
      rawVal: `${
        groupedData[key][0][edgeName] ? groupedData[key][0][edgeName] : ""
      }`,
      formattedVal: `${
        groupedData[key][0][edgeName] ? groupedData[key][0][edgeName] : ""
      }`,
    };
    eachEdgeRowObj.additionalInfo = { ...groupedData[key][0] };
    const edgeStatusArray: string[] = [];
    eachSetData.unshift({ ...eachEdgeRowObj });
    console.log("each set data 11222", eachSetData);
    formattedData = [...formattedData, ...eachSetData];
  });
  console.log("check formatted data", formattedData);
  return formattedData;
};
