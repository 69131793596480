import {
  Icon,
  LoadingIndicator,
  Table,
  Checkbox,
} from "@abb/abb-common-ux-react";
import * as React from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import * as serverTypes from "../Type/TypeServers";
import "./Grid.css";

const ServerGrid = (props: serverTypes.IServerGridProps) => {
  const commonState = useSelector((state: IState) => state.commonState);
  const getHeader = () => {
    const headerRow: JSX.Element[] = props.header.map((eachHeader, index) => {
      let headerCellClassName = "header-cell";
      if (eachHeader.className) {
        headerCellClassName += ` ${eachHeader.className}`;
      }
      if (props.isSortEnabled && eachHeader.isSortable) {
        headerCellClassName += ` sortable-header`;
      }
      if (eachHeader.id === props.sortedColumnId) {
        return (
          <th
            key={`heder${index}`}
            className={headerCellClassName}
            style={{ borderBottom: "4px solid #2E92FA" }}
            onClick={() =>
              props.isSortEnabled && eachHeader.isSortable && props.onSort
                ? props.onSort(eachHeader.id, props.sortDirection)
                : () => null
            }
          >
            <div className="header-content-wrap">
              <span>{eachHeader.displayName}</span>
              <span>
                {props.sortDirection && props.sortDirection !== "unsorted" ? (
                  <Icon
                    name={props.sortDirection === "asc" ? "abb/down" : "abb/up"}
                  />
                ) : (
                  <span />
                )}
              </span>
            </div>
          </th>
        );
      } else {
        return (
          <th
            key={`heder${index}`}
            className={headerCellClassName}
            onClick={() =>
              props.isSortEnabled && eachHeader.isSortable && props.onSort
                ? props.onSort(eachHeader.id, props.sortDirection)
                : () => null
            }
          >
            <div className="header-content-wrap">
              <span>{eachHeader.displayName}</span>
            </div>
          </th>
        );
      }
    });
    if (
      (props.isDeleteActionEnabled || props.isViewOptionsActionEnabled) &&
      props.data &&
      props.data.length > 0
    ) {
      headerRow.push(<th className="actions-header-column" />);
    }
    if (props.isExpansionEnabled) {
      headerRow.unshift(<th className="expand-header-column" />);
    }
    return (
      <thead>
        <tr>{headerRow}</tr>
      </thead>
    );
  };
  const getBody = () => {
    let eachRow: JSX.Element[] = [];
    const body: JSX.Element[] = [];
    props.data.map((item: serverTypes.IServersGridRowObj, itemIndex) => {
      eachRow = [];
      const groupedRowData: any = {};
      item.data.map((eachCellObj) => {
        groupedRowData[eachCellObj.id] = eachCellObj;
      });
      props.header.map((dataObj: serverTypes.ITableHeaderObj, index) => {
        const cellVal =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["formattedVal"]
            : "";
        const customClassName =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["className"]
            : undefined;
        let cellClassName = "cell";
        if (customClassName) {
          cellClassName += ` ${customClassName}`;
        }
        eachRow.push(
          <td className={cellClassName}>
            <div
              className={
                item.rowType === "server" && index === 0 ? "server-padding" : ""
              }
            >
              {cellVal}
            </div>
          </td>
        );
      });
      if (props.isDeleteActionEnabled || props.isViewOptionsActionEnabled) {
        eachRow.push(
          <td className="action-cell">
            <div className="action-icons-container">
              {props.isDeleteActionEnabled && item.rowType === "edge" ? (
                <div className="action-icon-wrap">
                  <Icon
                    name="abb/trash"
                    className={
                      item.isDeleteActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={() =>
                      props.onActionClick
                        ? props.onActionClick("delete", { ...item })
                        : null
                    }
                  />
                </div>
              ) : null}
              {props.isViewOptionsActionEnabled && item.rowType === "edge" ? (
                <div className="action-icon-wrap">
                  <Icon
                    name="abb/menu-narrow"
                    className={
                      item.isViewOptionsActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={() =>
                      props.onActionClick
                        ? props.onActionClick("viewOptions", { ...item })
                        : null
                    }
                  />
                  {props.showPopUp && props.selectedRow.id === item.id ? (
                    <React.Fragment>
                      <div
                        className="pop-up-overlay"
                        onClick={() =>
                          props.onPopUpAction("closePopUp", { ...item })
                        }
                      />
                      <div className="pop-up-wrap">
                        <div
                          className={
                            commonState.theme === "light"
                              ? "pop-up-arrow-light"
                              : "pop-up-arrow-dark"
                          }
                        />
                        <div
                          className={
                            commonState.theme === "light"
                              ? "pop-up-view light-theme"
                              : "pop-up-view content-dark-theme"
                          }
                        >
                          <div
                            className="more-option-popup-row"
                            onClick={() =>
                              props.onPopUpAction("openSettings", { ...item })
                            }
                          >
                            <Icon name="abb/settings" sizeClass="small" />
                            <div>Upload File</div>
                          </div>
                          <div
                            className="more-option-popup-row"
                            onClick={() =>
                              props.onPopUpAction("copyEdgeId", { ...item })
                            }
                          >
                            <Icon name="abb/copy" sizeClass="small" />
                            <div>Copy Hosting Edge Id</div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              ) : null}
            </div>
          </td>
        );
      }
      if (props.isExpansionEnabled) {
        eachRow.unshift(
          <td
            className={
              item.rowType === "server"
                ? "expansion-cell server-expansion-cell"
                : "expansion-cell edge-expansion-cell"
            }
            style={{ paddingLeft: (item.level - 1) * 15 }}
            onClick={() =>
              props.onExpandCollapse && item.isExpandable
                ? props.onExpandCollapse({ ...item })
                : () => null
            }
          >
            {item.isExpandable ? (
              item.isExpanded ? (
                <Icon name="abb/down" />
              ) : (
                <Icon name="abb/right" />
              )
            ) : null}
          </td>
        );
      }
      let rowClass = item.isClickable ? "row-view click-class" : "row-view";
      if (!item.isVisible) {
        rowClass += ` hidden-row-view`;
      }
      if (item.rowType === "server") {
        if (!props.data[itemIndex + 1]) {
          rowClass += ` no-border-bottom`;
        } else if (props.data[itemIndex + 1].rowType === "server") {
          rowClass += ` faded-row-view`;
        }
      }
      if (item.isVisible) {
        body.push(
          <tr
            className={rowClass}
            onClick={() =>
              item.isClickable && props.onRowClick
                ? props.onRowClick(item)
                : () => null
            }
          >
            {eachRow}
          </tr>
        );
      }
    });
    return <tbody>{body}</tbody>;
  };
  return (
    <React.Fragment>
      <Table>
        {getHeader()}
        {props.isLoading ? (
          <div className="loading-container">
            <LoadingIndicator
              type="radial"
              sizeClass="medium"
              determinate={true}
            />
          </div>
        ) : props.data && props.data.length > 0 ? (
          getBody()
        ) : (
          <div />
        )}
      </Table>
      {props.data && props.data.length > 0 ? (
        <div />
      ) : (
        <Table>
          <tbody>
            <tr>
              <td className="no-data-container">{props.noDataText}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </React.Fragment>
  );
};
export default ServerGrid;
