import React from "react";
import "./Tree.css";
import { Icon } from "@abb/abb-common-ux-react";

export interface ITree {
  data: ITreeDataObj[];
  selectedNodeId: string | undefined;
  handleExpandCollapse: (event: any, node: ITreeDataObj) => void;
  handleNodeSelection: (event: any, node: ITreeDataObj) => void;
  isDisabled?: boolean;
}
export interface ITreeDataObj {
  id: string;
  displayName: string;
  rawData: any;
  isExpandable?: boolean;
  isExpanded?: boolean;
  isVisible?: boolean;
  isTitle?: boolean;
  level: number;
}
const Tree = (props: ITree) => {
  const [treeData, setTreeData] = React.useState([] as ITreeDataObj[]);
  React.useEffect(() => {
    setTreeData([...props.data]);
  }, []);
  const onExpandCollapse = (item: ITreeDataObj) => {
    console.log("check expand");
  };
  const getTreeBody = () => {
    const treeBodyView: JSX.Element[] = [];
    treeData.map((item: ITreeDataObj) => {
      let itemClassName: string = `each-node-view node-level-${item.level}`;
      if (!item.isVisible) {
        itemClassName += " hidden-tree-class";
      }
      if (item.isExpanded) {
        itemClassName += " node-expanded-class";
      }
      if (item.rawData && item.rawData.nodeId === props.selectedNodeId) {
        itemClassName += " node-selected";
      }
      if (props.isDisabled) {
        itemClassName += " node-disabled";
      }
      treeBodyView.push(
        <div
          className={itemClassName}
          style={{ paddingLeft: (item.level - 1) * 15 }}
          onClick={(e) => props.handleNodeSelection(e, { ...item })}
        >
          {/* {item.isExpandable ? (
            <div className="node-left-space node-view-right-icon">
              {item.isExpanded ? (
                // <Icon name="abb/down" />
                <div className="tree-icon">-</div>
              ) : (
                <div className="tree-icon">+</div>
              )}
            </div>
          ) : (
            <div className="node-left-space" />
          )} */}
          {item.isExpandable ? (
            <div className="node-left-space node-view-right-icon">
              {item.isExpanded ? (
                <Icon
                  name="abb/down"
                  onClick={(e) => props.handleExpandCollapse(e, { ...item })}
                />
              ) : (
                <Icon
                  name="abb/right"
                  onClick={(e) => props.handleExpandCollapse(e, { ...item })}
                />
              )}
            </div>
          ) : (
            <div className="node-left-space" />
          )}
          <label className="each-node-label">{item.displayName}</label>
        </div>
      );
    });
    return treeBodyView;
  };
  const treeBody = getTreeBody();
  return (
    <div className="tree-body-view">
      <div className="tree-body-wrap">{treeBody}</div>
    </div>
  );
};
export default Tree;
