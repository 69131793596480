import React from "react";
import "./App.css";
import ViewsConatiner from "./Views/ViewsContainer";
import {
  AuthProvider,
  AuthConfiguration,
  AuthModel,
  AuthEvents,
} from "@iaedge/auth";
import { Button, Dialog } from "@abb/abb-common-ux-react";
import { envSettings } from "./EnviornmentSettings/Settings";
import Store from "./Store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

export interface IAuthHelperModel {
  getAccessToken(): string;
  getTenant(): string;
  logOut(): void;
}
export const commonAuthHelper: IAuthHelperModel = {
  getAccessToken: () => "",
  getTenant: () => "",
  logOut: () => "",
};

const App = () => {
  let authModel = {} as AuthModel;
  if (envSettings.connectivityMode !== "connected") {
    authModel = {
      authority: envSettings.authority,
      clientId: envSettings.oidc_clientId,
      redirectUrl: envSettings.oidc_redirectUri,
      scope: "openid profile offline_access",
      postLogoutRedirectUrl: envSettings.postLogoutRedirectUri,
      tokenRenewalOffsetSeconds: 10,
    };
  } else {
    authModel = {
      b2cName: envSettings.b2cName,
      clientId: envSettings.clientId,
      redirectUrl: envSettings.redirectUri,
      postLogoutRedirectUrl: envSettings.postLogoutRedirectUri,
      scope: `https://${envSettings.b2cName}.onmicrosoft.com/${envSettings.solution}/instance`,
      tokenRenewalOffsetSeconds: 10,
    };
  }
  const config: AuthConfiguration = {
    authType:
      envSettings.connectivityMode !== "connected" ? "OIDC" : "AbilityB2C",
    storage: "localStorage",
    applicationName: "Modbus Configuration Utility",
    authModel: {
      ...authModel,
    },
    keepLiveSession: false,
    //timeOutSeconds: 3600,
    onEventRegister: (methods: AuthEvents) => {
      commonAuthHelper.getAccessToken = methods.getToken;
      commonAuthHelper.getTenant = methods.getTenantName;
      commonAuthHelper.logOut = methods.logout;
    },
  };
  if (envSettings.isAuthenticationEnabled) {
    return (
      <div className="App">
        <AuthProvider {...config}>
          {/* <AuthProvider> */}
          <AppContainer />
        </AuthProvider>
      </div>
    );
  } else {
    return (
      <div className="App">
        <AppContainer />
      </div>
    );
  }
};

export const AppContainer = () => {
  return (
    <Provider store={Store}>
      <BrowserRouter basename={`${envSettings.prefix}`}>
        <ViewsConatiner />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
