import Axios from "axios";
import { commonAuthHelper } from "../App";
import { envSettings } from "../EnviornmentSettings/Settings";

export const getErrorMessage = (e: any, info?: string) => {
  let errorMessage: string = "";
  if (e.response && e.response.status === 400) {
    if (e.response.data) {
      errorMessage = e.response.data;
    } else {
      errorMessage = "400 bad request.";
    }
  } else {
    errorMessage =
      e.response && e.response.data ? e.response.data.errorMessage : "Error";
  }
  return errorMessage;
};

export const setCommonHeaders = () => {
  let token = commonAuthHelper.getAccessToken();
  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  if (envSettings.connectivityMode === "connected") {
    Axios.defaults.headers.common["X-Tenant"] = localStorage.getItem("tenant");
  }
};
