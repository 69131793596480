import React from "react";
import "./UploadModal.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Dialog,
  Input,
  Dropdown,
  DropdownOption,
  Button,
  Icon,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Reducer";
import * as serverActions from "../Action/ActionServers";
import * as serverTypes from "../Type/TypeServers";
import FilePickerButton from "./FilePickerButton";

const UploadModal = (props: RouteComponentProps) => {
  const serversState = useSelector((state: IState) => state.serversState);
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(serverActions.hideUpload());
  };
  //console.log("user data", usersState.selectedIdProvider);
  const handleSubmit = () => {
    console.log("submitting....");
    dispatch(
      serverActions.submitFiles([...serversState.nodeSetFiles], {
        ...serversState.selectedServer,
      })
    );
  };
  const handleFilePick = (e: any) => {
    e.preventDefault();
    const files: [] = e.target.files;
    const currentFiles: any[] = [...serversState.nodeSetFiles];
    const currentFileNames = [...serversState.fileNames];
    if (serversState.isMutlipleFileUpload) {
      for (let i = 0; files[i]; i++) {
        currentFileNames.push((files[i] as any).name);
        currentFiles.push(files[i]);
      }
    } else {
      for (let i = 0; files[i]; i++) {
        currentFileNames[0] = (files[i] as any).name;
        currentFiles[0] = files[i];
      }
    }
    dispatch(serverActions.pickFile([...currentFileNames], [...currentFiles]));
  };
  console.log("check uploaded files", serversState.nodeSetFiles);
  const getFileNames = () => {
    const fileNamesView = serversState.fileNames.map((item, index) => {
      return (
        <div
          key={`${item}_${index}`}
          className={
            commonState.theme === "light"
              ? "each-name-wrap"
              : "each-name-wrap content-dark-theme"
          }
        >
          <label>{item}</label>
          <div
            className="file-close-icon"
            onClick={() => dispatch(serverActions.removeFile(index))}
          >
            <Icon name="abb/close" sizeClass="small" />
          </div>
        </div>
      );
    });
    return <div className="file-name-wrap">{fileNamesView}</div>;
  };
  return (
    <Dialog
      showCloseButton={true}
      closeOnEscape={true}
      closeOnLostFocus={false}
      dimBackground={true}
      isOpen={true}
      onClose={() => dispatch(serverActions.hideUpload())}
      title={"Upload Configuration file"}
      className="file-upload-modal"
    >
      <div className="create-user-form">
        <React.Fragment>
          <div className="file-upload-container">
            {getFileNames()}
            <div className="file-upload-button-wrap">
              <FilePickerButton
                onChange={(e) => handleFilePick(e)}
                acceptTypes=".xlsx"
              >
                <Button
                  sizeClass="small"
                  type="normal"
                  text="Browse"
                  // disabled={
                  //   props.loadState === "loading" ||
                  //   props.updateLoadState === "loading" ||
                  //   isLoadingUpload ||
                  //   [0, 7, 8].indexOf(
                  //     serverDetails.configureState!
                  //   ) < 0
                  // }
                />
              </FilePickerButton>
              {/* {[0, 7, 8].indexOf(
                                  serverDetails.configureState!
                                ) < 0 ? (
                                  <div className="upload-status">
                                    {serverDetails.configureStatusMessage}
                                  </div>
                                ) : (
                                  <div />
                                )} */}
            </div>
          </div>
        </React.Fragment>
        <div className="modal-submit-cancel">
          <Button
            sizeClass="medium"
            type="normal"
            className="btn-cancel-upload-modal"
            onClick={handleCancel}
            text="Cancel"
          />
          <Button
            sizeClass="medium"
            type="primary-blue"
            onClick={handleSubmit}
            text="Submit"
            className="btn-submit-upload-modal"
            disabled={serversState.nodeSetFiles.length === 0}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default withRouter(UploadModal);
