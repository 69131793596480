import React from "react";
import "./ReleaseNotes.css";
import {
  AppContainer,
  AppMainContent,
  Dialog,
  Icon,
  Table,
  ThemeContext,
} from "@abb/abb-common-ux-react";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import { useSelector } from "react-redux";
import { IState } from "../../../Reducer";

interface IReleaseNotesProps {
  showReleaseNotes: boolean;
  onCloseReleaseNotes: () => void;
}
const ReleaseNotes = (props: IReleaseNotesProps) => {
  const commonState = useSelector((state: IState) => state.commonState);
  const handleEscape = (e: any) => {
    if (e.keyCode === 27) {
      props.onCloseReleaseNotes();
    }
  };
  React.useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);
  const currentTime = new Date();
  let formattedDate = currentTime.toLocaleString();
  formattedDate = formattedDate.replace(/\//g, "-");
  formattedDate = formattedDate.replace(/,/g, "");
  return (
    // <Dialog
    //   isOpen={props.showReleaseNotes}
    //   showCloseButton={true}
    //   onClose={() => props.onCloseReleaseNotes()}
    //   className={`release-note-view`}
    //   title="Release Notes"
    // >
    <div
      className={`release-notes-wrap ${commonState.theme === "light"
          ? "light-view-container"
          : "dark-view-container"
        }`}
    >
      <div className="notes-heading">
        <span>Release Notes </span>
        <div>
          <Icon
            name="abb/close"
            className="notes-close-icon"
            onClick={() => props.onCloseReleaseNotes()}
          />
        </div>
      </div>
      <AppContainer theme={commonState.theme}>
        <AppMainContent>
          <div className="release-note-container">
            <div className="release-note-content">
              <p />
              <div className="release-notes-header-wrap">
                <div className="release-note-version">{`VERSION  1.0.7`}</div>
                <div className="release-note-time-stamp">{`${formattedDate}`}</div>
              </div>
              <hr />
              <div className="release-notes-app-details">
                Modbus Configuration Tool provides ability to upload Modbus
                configuration excel data sheet for configuring connectivity
                between Modbus TCP devices and the Edge.
              </div>

              <p>
                <p className="release-note-contentheaders">Key Features</p>
                <ul>
                  <li>
                    Upload the Modbus configuration Excel sheet with Modbus TCP
                    channels, devices, variables etc. to match the actual Modbus
                    device configuration for the connected Modbus TCP devices.
                  </li>
                  <li>
                    Based on uploaded configuration expose data from Modbus TCP
                    devices as OPC UA Tags which can then be configured from OPC
                    UA Configuration Tool.
                  </li>
                </ul>
              </p>
              <p>
                <p className="release-note-contentheaders">Known Issues</p>
                <Table>
                  <tr>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Workarounds, Clarifications and helpful hints
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue Id
                      </div>
                    </th>
                  </tr>
                  <tbody>
                    <tr>
                      <td>
                        Sampling interval for Modbus variables does not work if configured from OPC UA Config Tool
                      </td>
                      <td>
                        Configure the sampling interval of the Modbus variables using the Modbus Configuration excel template.
                        Ensure ScanMode is set as TagSpecificScanRate in the device tab of Modbus excel template
                      </td>
                      <td>EDGUMODCONN-OL-1200-54658</td>
                    </tr>

                    <tr>
                      <td>
                        Modbus Configuration Utility will show an error popup after
                        logging in or when the theme is changed. This occurs only after
                        the application is updated from Edge Management Portal.
                        No functionality is impacted due to the error popup.
                      </td>
                      <td>
                        Uninstalling and Reinstalling Modbus Configuration Utility
                        will resolve this issue.
                      </td>
                      <td>EDGMODCONN-OL-2010-77706</td>
                    </tr>
                  </tbody>
                </Table>
              </p>
              <p>
                <p className="release-note-contentheaders">Resolved Issues</p>
                <Table>
                  <tr>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Correction or Fix
                      </div>
                    </th>
                    <th>
                      <div className="release-notes-table-each-header">
                        Issue Id
                      </div>
                    </th>
                  </tr>
                  <tbody>
                    <tr>
                      <td>
                        {`Uploading Modbus Configuration template multiple times when there are large number of tags (>1000) already subscribed will lead to a drop in existing subscriptions.`}
                      </td>
                      <td>
                        This issue is resolved.
                      </td>
                      <td>EDGMODCONN-OL-1200-59528</td>
                    </tr>
                  </tbody>
                </Table>
              </p>
            </div>
          </div>
        </AppMainContent>
      </AppContainer>
    </div>
    //</Dialog>
  );
};

export default ReleaseNotes;
