import { AppMainContent, Input } from "@abb/abb-common-ux-react";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ServersGrid from "./Grid/Grid";
import UploadModal from "./UploadModal/UploadModal";
import * as serversType from "./Type/TypeServers";
import { serversGridHeaders } from "./DataFormatter/DataFormatterServers";
import "./Servers.css";
import { useDispatch, useSelector } from "react-redux";
import * as serversActions from "./Action/ActionServers";
import * as commonActions from "../../Common/Action/ActionCommon";
import { IState } from "../../Reducer";
import CommonLoader from "../../Common/Components/CommonLoader/CommonLoader";
import _ from "underscore";

let filterTimer: any;
const Servers = (props: RouteComponentProps) => {
  const serversState = useSelector((state: IState) => state.serversState);
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getPreference());
    dispatch(serversActions.getServers());
  }, []);
  useEffect(() => {
    clearTimeout(filterTimer);
    filterTimer = setTimeout(
      () => dispatch(serversActions.performFilter()),
      1000
    );
  }, [serversState.filterText]);
  const landingApiCalls = (getAllDetails: () => void) => {
    //dispatch(commonActions.getPreference());
    getAllDetails();
  };
  const checkPreferenceApiCalls = async () => {
    await dispatch(commonActions.getPreference());
  };
  const onActionClick = (
    actionType: "viewOptions" | "delete",
    rowObj: serversType.IServersGridRowObj
  ) => {
    if (actionType === "delete") {
      dispatch(serversActions.selectServerToDelete({ ...rowObj }));
    } else if (actionType === "viewOptions") {
      dispatch(serversActions.showMoreOptions({ ...rowObj }));
    }
  };
  const onPopUpAction = (
    actionType: "openSettings" | "copyEdgeId" | "closePopUp",
    rowObj: serversType.IServersGridRowObj
  ) => {
    if (actionType === "openSettings") {
      dispatch(serversActions.hideMoreOptions());
      dispatch(serversActions.showUpload({ ...rowObj }));
    } else if (actionType === "copyEdgeId") {
      dispatch(serversActions.hideMoreOptions());
      let copyText = rowObj.additionalInfo ? rowObj.additionalInfo.id : "";
      const el = document.createElement("textarea");
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    } else {
      dispatch(serversActions.hideMoreOptions());
    }
  };
  // const onExpandCollapse = (item: serversType.IServersGridRowObj) => {
  //   dispatch(serversActions.handleExpandCollpase({ ...item }));
  // };
  return (
    <React.Fragment>
      {serversState.isLoading || commonState.isLoading ? (
        <CommonLoader />
      ) : (
        <div />
      )}
      {serversState.isLoading ? <div className="loader-mask" /> : <div />}
      {!commonState.isPreferenceLoaded ? (
        <div className="theme-loader-mask" />
      ) : (
        <div />
      )}
      <AppMainContent className="servers-view-wrap">
        <div
          className={
            commonState.theme === "light"
              ? `servers-container light-theme`
              : `servers-container dark-theme`
          }
        >
          <div className="server-header-label">Modbus Clients</div>
          <hr />
          <div className="servers-input-wrap">
            <Input
              dataType="text"
              icon="abb/search"
              className="servers-search-input"
              value={serversState.filterText}
              //disabled={serversState.serversDataCopy.length === 0}
              onValueChange={(value) =>
                dispatch(serversActions.handleFilterInput(value))
              }
            />
          </div>
          <div className="servers-grid-wrap">
            <ServersGrid
              data={serversState.serversData}
              header={[...serversGridHeaders]}
              isViewOptionsActionEnabled={true}
              isDeleteActionEnabled={false}
              isExpansionEnabled={false}
              onActionClick={onActionClick}
              //onExpandCollapse={onExpandCollapse}
              noDataText="No data found ..!"
              selectedRow={{ ...serversState.selectedServer }}
              showPopUp={serversState.showOptionsPopup}
              onPopUpAction={onPopUpAction}
            />
          </div>
          {serversState.showUpload ? <UploadModal /> : <div />}
        </div>
      </AppMainContent>
    </React.Fragment>
  );
};

export default withRouter(Servers);
