import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Servers from "./Servers/Servers";
import UnAuthorizedView from "../Common/Components/UnAuthorizedView/UnAuthorizedView";
import Documentation from "../Common/Components/Documentation/Documentation";
import { useSelector } from "react-redux";
import { IState } from "../Reducer";

const AppRoutes = () => {
  const commonState = useSelector((state: IState) => state.commonState);
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={!commonState.showUnAuthorized ? Servers : UnAuthorizedView}
      />
      <Route
        path="/help"
        component={
          !commonState.showUnAuthorized ? Documentation : UnAuthorizedView
        }
      />
    </Switch>
  );
};
export default withRouter(AppRoutes);
