import * as serverTypes from "../Type/TypeServers";
export const getServers = (): serverTypes.IGetServers => {
  return {
    type: "GET_SERVERS",
  };
};
// export const handleExpandCollpase = (
//   clickedRow: serverTypes.IServersGridRowObj
// ): serverTypes.IExpandCollapse => {
//   return {
//     type: "ON_EXPAND_COLLAPSE",
//     clickedRow,
//   };
// };

export const selectServerToDelete = (
  rowObj: serverTypes.IServersGridRowObj
): serverTypes.ISelectServerToDelete => {
  return {
    type: "SELECT_SERVER_TO_DELETE",
    rowObj,
  };
};
export const hideDeleteWarning = (): serverTypes.IHideWarningDialog => {
  return {
    type: "HIDE_WARNING_DIALOG",
  };
};
export const handleFilterInput = (
  text: string
): serverTypes.IHandleFilterInputText => {
  return {
    type: "HANDLE_FILTER_INPUT_TEXT",
    text,
  };
};

export const performFilter = (): serverTypes.IPerformFilter => {
  return {
    type: "PERFORM_FILTER",
  };
};
export const showMoreOptions = (
  rowObj: serverTypes.IServersGridRowObj
): serverTypes.IShowMoreOptions => {
  return {
    type: "SHOW_MORE_OPTIONS",
    rowObj,
  };
};
export const hideMoreOptions = (): serverTypes.IHideMoreOptions => {
  return {
    type: "HIDE_MORE_OPTIONS",
  };
};
export const showUpload = (
  rowObj: serverTypes.IServersGridRowObj
): serverTypes.IShowUpload => {
  return {
    type: "SHOW_UPLOAD",
    rowObj,
  };
};
export const hideUpload = (): serverTypes.IHideUpload => {
  return {
    type: "HIDE_UPLOAD",
  };
};

export const pickFile = (
  fileNames: string[],
  nodeSetFiles: any[]
): serverTypes.IPickFile => {
  return {
    type: "PICK_FILE",
    fileNames,
    nodeSetFiles,
  };
};

export const removeFile = (index: number): serverTypes.IRemoveFile => {
  return {
    type: "REMOVE_FILE",
    index,
  };
};
// export const clearAllFiles = (): serverTypes.IClearAllFiles => {
//   return {
//     type: "CLEAR_ALL_FILES",
//   };
// };
export const submitFiles = (
  filesToBeSubmitted: any[],
  selectedRow: serverTypes.IServersGridRowObj
): serverTypes.ISubmitUploadedFile => {
  return {
    type: "SUBMIT_UPLOADED_FILE",
    filesToBeSubmitted,
    selectedRow,
  };
};
