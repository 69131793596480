import * as serverTypes from "../Type/TypeServers";

export interface IServersDetailsStateType {
  serversData: serverTypes.IServersGridRowObj[];
  serversDataCopy: serverTypes.IServersGridRowObj[];
  isLoading: boolean;
  selectedServer: serverTypes.IServersGridRowObj;
  showWarningDialog: boolean;
  filterText: string;
  showOptionsPopup: boolean;
  showUpload: boolean;
  showConnectToServerModal: boolean;
  nodeSetFiles: any[];
  fileNames: string[];
  isMutlipleFileUpload: boolean;
}

const initialState: IServersDetailsStateType = {
  serversData: [],
  serversDataCopy: [],
  isLoading: false,
  selectedServer: {} as serverTypes.IServersGridRowObj,
  showWarningDialog: false,
  filterText: "",
  showOptionsPopup: false,
  showUpload: false,
  showConnectToServerModal: false,
  nodeSetFiles: [],
  fileNames: [],
  isMutlipleFileUpload: false,
};

export default function (
  state: IServersDetailsStateType = initialState,
  action: serverTypes.IServersActions
): IServersDetailsStateType {
  switch (action.type) {
    case "GET_SERVERS":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_SERVERS":
      return {
        ...state,
        serversData: action.payload,
        serversDataCopy: action.payload,
        isLoading: false,
      };
    case "ON_EXPAND_COLLAPSE":
      const updatedData = [...state.serversData];
      updatedData.map((item) => {
        if (item.id === action.clickedRow.id) {
          item.isExpanded = !item.isExpanded;
        }
      });
      if (action.clickedRow.isExpanded) {
        updatedData.map((item) => {
          if (
            item["id"].indexOf(action.clickedRow.id) >= 0 &&
            item.level > action.clickedRow.level
          ) {
            item.isExpanded = false;
            item.isVisible = false;
          }
        });
      } else {
        updatedData.map((item) => {
          if (
            item.id.indexOf(action.clickedRow.id) >= 0 &&
            item.level === action.clickedRow.level + 1
          ) {
            item.isExpanded = false;
            item.isVisible = true;
          }
        });
      }
      return {
        ...state,
        serversData: [...updatedData],
      };
    case "SELECT_SERVER_TO_DELETE":
      return {
        ...state,
        selectedServer: { ...action.rowObj },
        showWarningDialog: true,
      };
    case "HIDE_WARNING_DIALOG":
      return {
        ...state,
        showWarningDialog: false,
        selectedServer: {} as serverTypes.IServersGridRowObj,
      };
    case "HANDLE_FILTER_INPUT_TEXT":
      return {
        ...state,
        filterText: action.text,
        //serversData: [...filteredData],
      };
    case "PERFORM_FILTER":
      ///////only filters edges/// later use state.serversDataCopy only////
      const dataToBeFiltered = [
        ...state.serversDataCopy.filter((item) => item.rowType === "edge"),
      ];
      const filteredData: serverTypes.IServersGridRowObj[] = [];
      const filteredIds: string[] = [];
      if (state.filterText !== "") {
        dataToBeFiltered.map((item) => {
          let isMatch = false;
          item.data.map((eachObj) => {
            if (
              item.displayName
                .toLowerCase()
                .indexOf(state.filterText.toLowerCase()) >= 0
            ) {
              isMatch = true;
            }
            if (item.rowType === "server" && eachObj.id === "serverstatus") {
              if (eachObj.rawVal) {
                (eachObj.rawVal as string[]).map((eachStatus) => {
                  if (
                    eachStatus
                      .toLowerCase()
                      .indexOf(state.filterText.toLowerCase()) >= 0
                  ) {
                    isMatch = true;
                  }
                });
              }
            }
          });
          if (isMatch) {
            filteredIds.push(item.id);
          }
        });
        dataToBeFiltered.map((item) => {
          let objToBeKept = false;
          let itemCopy = { ...item };
          filteredIds.map((filteredId) => {
            if (
              filteredId.indexOf(item.id) >= 0 ||
              item.id.indexOf(filteredId) >= 0
            ) {
              objToBeKept = true;
              if (
                !(item.id.indexOf(filteredId) >= 0 && filteredId !== item.id)
              ) {
                itemCopy.isVisible = true;
              }
              if (
                (filteredId.indexOf(item.id) >= 0 ||
                  item.id.indexOf(filteredId) >= 0) &&
                filteredId !== item.id &&
                item.isExpandable
              ) {
                itemCopy.isExpanded = true;
              }
            }
          });
          if (objToBeKept) {
            filteredData.push({ ...itemCopy });
          }
        });
      } else {
        filteredData.push(...dataToBeFiltered);
      }
      return {
        ...state,
        serversData: [...filteredData],
      };
    case "SHOW_MORE_OPTIONS":
      return {
        ...state,
        showOptionsPopup: true,
        selectedServer: { ...action.rowObj } as serverTypes.IServersGridRowObj,
      };
    case "HIDE_MORE_OPTIONS":
      return {
        ...state,
        showOptionsPopup: false,
        selectedServer: {} as serverTypes.IServersGridRowObj,
      };
    case "SHOW_UPLOAD":
      return {
        ...state,
        showUpload: true,
        selectedServer: { ...action.rowObj },
      };
    case "HIDE_UPLOAD":
      return {
        ...state,
        showUpload: false,
        selectedServer: {} as serverTypes.IServersGridRowObj,
        nodeSetFiles: [],
        fileNames: [],
      };
    case "PICK_FILE":
      return {
        ...state,
        fileNames: [...action.fileNames],
        nodeSetFiles: [...action.nodeSetFiles],
      };
    case "REMOVE_FILE":
      const currentFiles: any[] = [...state.nodeSetFiles];
      const currentFileNames = [...state.fileNames];
      currentFiles.splice(action.index, 1);
      currentFileNames.splice(action.index, 1);
      console.log("check removal", currentFileNames);
      return {
        ...state,
        nodeSetFiles: [...currentFiles],
        fileNames: [...currentFileNames],
      };
    case "CLEAR_ALL_FILES":
      return {
        ...state,
        nodeSetFiles: [],
        fileNames: [],
      };
    case "SUBMIT_UPLOADED_FILE":
      return {
        ...state,
        isLoading: true,
      };
    case "ON_SUCCESS_SERVERS":
      return {
        ...state,
        isLoading: false,
      };
    case "ON_FAIL_SERVERS":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
