import { ITreeDataObj } from "../Common/Components/Tree/Tree";
import { ITabData } from "../Common/Type/TypeCommon";

export const tabData: ITabData[] = [
  {
    id: "servers",
    displayName: "Servers",
    path: "/",
    indexNumber: 0,
  },
];
export const helpPageTreeData: ITreeDataObj[] = [
  {
    id: "%1.introduction%",
    displayName: "Introduction",
    rawData: { nodeId: "%1.introduction%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%1.introduction%%1.1rbac%",
    displayName: "RBAC",
    rawData: { nodeId: "%1.introduction%%1.1rbac%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.installationandconfig%",
    displayName: "Installation & Configuration",
    rawData: { nodeId: "%2.installationandconfig%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%2.installationandconfig%%2.1.installation%",
    displayName: "Installation",
    rawData: { nodeId: "%2.installationandconfig%%2.1.installation%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.installationandconfig%%2.2.codmode%",
    displayName: "Installation – Connect-On-Demand mode",
    rawData: { nodeId: "%2.installationandconfig%%2.2.codmode%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.installationandconfig%%2.3.configuration%",
    displayName: "Configuration",
    rawData: { nodeId: "%2.installationandconfig%%2.3.configuration%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },

  {
    id: "%3.modbusconfigutility%",
    displayName: "Modbus Configuration Utility",
    rawData: { nodeId: "%3.modbusconfigutility%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%3.modbusconfigutility%%3.1.modbusconfigfileupload%",
    displayName: "Modbus Configuration File Upload",
    rawData: {
      nodeId: "%3.modbusconfigutility%%3.1.modbusconfigfileupload%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.modbusconfigutility%%3.2opcuamodbbusconfig%",
    displayName: "OPC UA Configuration Utility – Modbus Configuration",
    rawData: {
      nodeId: "%3.modbusconfigutility%%3.2opcuamodbbusconfig%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.exceltemplate%",
    displayName: "Modbus Configuration Excel Template",
    rawData: { nodeId: "%4.exceltemplate%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },

  {
    id: "%4.exceltemplate%%4.1.modbustcpchannel%",
    displayName: "ModbusTCPChannel",
    rawData: { nodeId: "%4.exceltemplate%%4.1.modbustcpchannel%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },

  {
    id: "%4.exceltemplate%%4.2.device%",
    displayName: "Device",
    rawData: { nodeId: "%4.exceltemplate%%4.2.device%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.exceltemplate%%4.3.communicationtimeout%",
    displayName: "ModbusTCPChannel",
    rawData: { nodeId: "%4.exceltemplate%%4.3.communicationtimeout%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.exceltemplate%%4.4.autodemotion%",
    displayName: "AutoDemotion",
    rawData: { nodeId: "%4.exceltemplate%%4.4.autodemotion%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.exceltemplate%%4.5.tag%",
    displayName: "Tag",
    rawData: { nodeId: "%4.exceltemplate%%4.5.tag%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.exceltemplate%%4.6.type%",
    displayName: "Type",
    rawData: { nodeId: "%4.exceltemplate%%4.6.type%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.exceltemplate%%4.7.variable%",
    displayName: "Variable",
    rawData: { nodeId: "%4.exceltemplate%%4.7.variable%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
];
