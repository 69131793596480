import React from "react";
import "./ViewsContainer.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AppRoutes from "./Routes";
import Header from "../Common/Components/Header/Header";
import { AbbBar, AppContainer } from "@abb/abb-common-ux-react";
import AppNotification from "../Common/Components/AppNotification/AppNotification";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../Reducer";
import TimeOutHandler from "../Common/Components/TimeOutHandler/TimeoutHandler";
import { envSettings } from "../EnviornmentSettings/Settings";
import { commonAuthHelper } from "../App";
import * as commonActions from "../Common/Action/ActionCommon";
import CommonLoader from "../Common/Components/CommonLoader/CommonLoader";
import Footer from "../Common/Components/Footer/Footer";

const ViewsConatiner = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const userLogout = () => {
    console.log("logging out");
    if (envSettings.connectivityMode === "connected") {
      localStorage.clear();
      sessionStorage.clear();
    }
    commonAuthHelper.logOut();
  };
  const commonState = useSelector((state: IState) => state.commonState);
  console.log("check preference data", commonState);
  return (
    <AppContainer
      className={`views-container ${
        commonState.theme === "light"
          ? "light-view-container"
          : "dark-view-container"
      }`}
      theme={commonState.theme}
    >
      {commonState.showNotification ? <AppNotification /> : <div />}
      <AbbBar
        blackAndWhiteLogo={false}
        productName="Modbus Configuration Utility"
      />
      <Header />
      <AppRoutes />
      <TimeOutHandler onLogout={userLogout} />
      <Footer />
    </AppContainer>
  );
};
export default withRouter(ViewsConatiner);
