import { formatServersData } from "./../DataFormatter/DataFormatterServers";
import { put, takeLatest, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as serversTypes from "../Type/TypeServers";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import * as apiEndPoints from "../../../Utils/ApiEndPoints";
import {
  getErrorMessage,
  setCommonHeaders,
} from "./../../../Utils/HelperFunctions";

function* fetchServers(action: serversTypes.IServersActions): any {
  setCommonHeaders();
  console.log("check sequence 333");
  try {
    const fetchedData = yield axios.get<any>(apiEndPoints.getServers);
    const receivedData = !envSettings.isOfflineMode
      ? [...fetchedData.data]
      : [...fetchedData.data.data];
    const formattdServersData: any = formatServersData([...receivedData]);
    console.log("fetching servers 1111", formattdServersData);
    yield put({
      type: "RECEIVED_SERVERS",
      payload: [...formattdServersData],
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}
function* submitUploadedFile(action: serversTypes.ISubmitUploadedFile): any {
  setCommonHeaders();
  try {
    const data = new FormData();
    const currentFiles: any[] = [...action.filesToBeSubmitted];
    for (let i = 0; currentFiles[i]; i++) {
      data.append("nodeSetFiles", currentFiles[i]);
    }
    const fetchedData = yield axios.post<any>(
      `${apiEndPoints.submitFile}/${action.selectedRow.additionalInfo.moduleId}/import`,
      data
    );
    console.log("fteched data 1111", fetchedData);
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Configuration file successfully uploaded.`,
    });
    yield put({
      type: "HIDE_UPLOAD",
    });
    yield put({
      type: "ON_SUCCESS_SERVERS",
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}

export default function* actionWatcherServers() {
  yield takeEvery("GET_SERVERS", fetchServers);
  yield takeEvery("SUBMIT_UPLOADED_FILE", submitUploadedFile);
}
