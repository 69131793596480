import React from "react";
import "./AppNotification.css";
import {
  Notification,
  NotificationContainer,
  NotificationData,
} from "@abb/abb-common-ux-react";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Reducer/index";
import * as commonActions from "../../Action/ActionCommon";

const AppNotification = (props: any) => {
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  const notificationData: NotificationData = {
    id: commonState.notificationType,
    type: "banner",
    severity: commonState.notificationType,
    timeout: commonState.notificationCloseDuration,
    text: commonState.notificationMessage,
    discreet: false,
    onClose: () => dispatch(commonActions.closeNotification()),
  };
  return (
    <div>
      <NotificationContainer
        notifications={[{ ...notificationData }]}
        style={{ position: "absolute", top: "0px", right: "0px" }}
        actionHandler={{
          remove: () => dispatch(commonActions.closeNotification()),
        }}
      />
    </div>
  );
};

export default AppNotification;
